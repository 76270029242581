import './App.css';
import Box1 from './Components/Box1';
import Box2 from './Components/Box2';


function App() {
  return (
    
      <div className="container">
      
        <Box1/>
        
        <Box2/>

        
    </div>
    
  );
}

export default App;
